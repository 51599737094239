.hackathonsMainDiv{
  width: 100%;
  padding: 50px;
  padding-bottom: 100px;
  padding-top: 30px;

}
.hackathonDiv{
    width: 48%;
    float: left;
    padding: 25px;
    background-color: blue;
    margin: 10px;
    border-radius: 17px;
    background: #fff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
    border: 3.2px solid #f9f9f9;
}
.hackathonDiv:hover{
    border: 3.2px solid #0068FF;
    cursor: pointer;
    transition: 0.6s;
}
.hackathonDiv h3,.communityDiv h3{
    color: #586C8A;
    font-family: mg-bold;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.hackathonDiv h1, .communityDiv h1{
    font-family: mg-bold;
    font-size: 28px;
    color: #293338;
    margin-bottom: 15px;
    white-space: nowrap;
    overflow: hidden;
}


.hackathonDiv p{
    font-family: mg-regular;
    font-size: 18px;
}
.hackathonDivLeft{
    width: 70%;
    float: left;

}
.hackathonTags{
    width: 100%;
    float: left;
}
.hackathonTags p{
    width:140px;
    float: left;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
   background-color: #FCFCFC;
    padding: 5px;
    border-radius: 8px;
}
.hackathonTags p:nth-child(2){
    margin-left: 10px;
}
.hackathonParticipants{
    width: 100%;
    float: left;
}
.hackathonParticipants img{
    float: left;
    width: 20px;
    height: 20px;
    border-radius: 100px;
}
.hackathonTags p{
    float: left;
}
.hackathonParticipants p{
    float: left;
    font-size: 14px;
    font-family: mg-medium;
    margin-left: 5px;
}
.hackathonDivRight{
    float: left;
    width: 30%;
}
.hackathonDivRight img{
    width: 80%;
    margin-left: 10%;
    border-radius: 8px;
    height: 110px;
    object-fit: contain;
}
.hackathonDivRight button{
    width: 100%;
    padding: 10px;
    border: none;
    background-color: black;
    border-radius: 12px;
    margin-top: 40px;
    opacity: 0.9;
}
.hackathonDivRight button:hover {
    opacity: 1;
}

.editDelete{
 width: 100%;
 float: left;
 padding-top: 0px;
 margin-top: -40px;
}
.editDelete button{
 text-align: center;
 width: 100px;
 float: left;
 padding: 0px;
 border-radius: 0px;
 background-color: transparent;
 color: black;
}
.editDelete button:nth-child(2) {
    color: red;
}


#countdown p{
    float: left;
    margin-left: 0px;
}
#countdown p:first-child {
    margin-left: 0px;
}
#day{
    background-color: #f0f0f0;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
}

.hackathonOrganise{
    width: 100%;
    float: left;
    padding: 70px;
    padding-bottom: 0px;
    padding-top: 20px;
}
.hackathonOrganise p{
    float: left;
    margin-top: 20px;
    font-size: 18px;
    background-color: #f0f0f0;
    padding: 2px;
    padding-left: 30px;
font-family: mg-medium;
    padding-right: 30px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 20px;
}
.hackathonOrganise p:nth-child(1){
margin-left: 0px;
}
.hackathonOrganise button{
  border-radius: 14px;
  padding: 4px!important;
  height: 45px;
  width: auto;
  margin-top:15px;
  padding-left: 20px!important;
padding-right: 20px !important;
}

#iconT{
    float: left;
    margin-right: 10px;
    margin-top: 5px;
}

#line{
    margin: 35px;
    width:35%;
    float: left;
    height: 4px;
    color: #F9F9F9;
}
#countdown{
  background-color: #f9f9f9;
  border: dashed #0068ff 1px;
}
#org{
    font-family: mg-bold;
    margin-right: 20px;
}
button.Joined.Hackathon {
    background: #0068ff;
}

.hackathonBtn{
    margin-top: 0px;
}

#number{
    font-size: 20px;
    font-family: mg-bold;
}

.communityDiv {
    width: 30%;
    float: left;
    padding: 25px;
    background-color: blue;
    margin: 10px;
    border-radius: 17px;
    background: #fff;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.06);
    border: 3.2px solid #f9f9f9;
}

.communityDiv .hackathonDivRight button {
    font-size: 14px;
    padding: 0px!important;
    height: 28px;
}
.hackathonParticipants button{
    background-color: #293338;
    float: left;
    font-size: 12px;
    height: 28px;
    width: 100px;
    padding: 0px;
    margin-left: 5px;
}

.view{
    background-color: #0068FF!important;
    color:#fff!important;
}
.withdraw{
    background-color: #f9f9f9 !important;
        color: #293338 !important;
}