body{
    background-color: #F3F6FE;
    margin: 0px;
    padding: 0px;
}
.dashboard_cms{
    display: flex;
    flex-direction: row;
}
.sidemenu{
    background-color: #323a49;
    background: linear-gradient(90deg,hsla(0,0%,7%,1),hsla(0,0%,7%,0.9));
    width: 15%;
    height: 100vh;
    overflow-y: scroll;
    margin-top: 0px;
    padding: 40px;
    padding-right: 0px;
    border-radius: 0px;
    position: fixed;
    overflow: hidden!important;
}

.rightmenu-items {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    height: 80%;
    overflow-y: scroll;
    padding-bottom: 40px;
}

.rightmenu-items a{
    color: rgba(255, 255, 255, .67);
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    cursor: pointer!important;
    text-transform: capitalize;
    display: flex;
    justify-content: left;
    align-items: center;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    margin-left: 7px;
    padding: 10px;
    margin-top: 0px;
    padding-left: 30px;
    border-left: solid  rgba(255,255,255,0.12) 2px;
    padding-bottom: 0px;
}
.dropdown-content a{
    margin-top: 10px;
}
.dropdown {
    margin-top: 20px;
    cursor: pointer!important;
}
span#icon{
    margin-top: -2px;
    margin-right: 8px;
}
#active_menu {
    color: #fff;
    border-right: solid #fff 3px !important;
    font-weight: 700;
    height: 30px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.rightmenu-logo{
    padding-left: 20px;
    width: 100%;
    margin-left: -8%;
    border-bottom: solid rgba(255,255,255,0.12) 1px;
    padding-top: 0px;
}
.rightmenu-logo a{
    text-decoration: none;
    color: white;
    font-size: 30px;
    overflow: hidden;
    text-transform: uppercase;
    font-weight: 800;
}
.dash-menu{
    display: flex;
    flex-direction: row;
}

.dash-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.topMenu {
    background-color: white;
    height: 100px;
    padding: 20px;
    padding-top: 0px;
    width: 85%;
    display: flex;
    position: fixed;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 35px;
    padding-top: 35px;
}
.rightdash{
    display: flex;
    flex-direction: column;
    width: 85%;
    margin-left: 15%;
}
.graphs{
    margin: 20px;
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
.totalStudents{
    width: 18.1%;
    padding: 20px;
    border: solid #f9f9f9 1px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
}

.totalStudents .icon{
    background-color: #EEF1FF;
    padding: 20px;
    border-radius: 10px;
    font-size: 12px;
    margin: 0px;
}
.icon-name{
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    line-height: 10px;
    align-items: flex-end;
}
.icon-name span{
    color: #323a49;
    opacity: 0.5;
    font-weight: 700;
    font-size: 11px;
    text-align: right;
}
.icon-name p{
    color: #323a49;
    opacity: 0.9;
    font-weight: 700;
    font-size: 32px;
}

.totalStudents .icon svg {
    width: 2em;
    height: 2em;
    color: #0068ff;
}
.qr-scan{
    margin: 30px;
    margin-top: 0px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    width: 95.5%;
}
.graphs .container {
    padding: 0px;
    margin: 0px;
    margin-top: 100px!important;
    min-width: 97%;
    background: white;
    padding-top: 0pc !important;
    border-radius: 0px;
    padding-left: 44px;
    padding-bottom:  55px !important;
}
.c100px {
    margin-top: 100px;
}

a.active {
    color: white;
    font-weight: 600;
}
.menu-footer{
    position: absolute;
    bottom: 0;
    background-color:#fff;
    border-radius: 10px;
    width: 94%;
    padding: 10px;
    margin: 10px;
    margin-left: -30px;
}


.graphs form.space {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
}
.graphs form.space  .col {
    flex: 1 0;
    display: flex;
    margin: 0px;
}
.graphs button.btn.btn-primary {
    width: auto;
    background: #1B2559!important;
    width: 145px;
    border-radius: 30px !important;
    padding: 0px !important;
    height: 31px;
    border: none;
    margin-left: 30px;
    margin-top: 30px;
}

.graphs .mb-3 {
    margin-bottom: 1rem !important;
    margin-left: 5px;
}
.graphs .mb-3 select{
    border: solid #f9f9f9 2px;
    width: 200px;
    font-size: 12px;
}
.graphs thead tr th, thead tr,td {
    background-color: white !important;
    color: #A0AEC0 !important;
    border: none;
    font-weight: 800 !important;
}
.graphs .table>:not(caption)>*>* {
    padding: .5rem .5rem;
    background-color: white;
    border-bottom-width: 0px;
    box-shadow: none;
}
.graphs td {
    background-color: white !important;
    color: #1B2559 !important;
    border: none;
    font-weight: 800 !important;
}
.graphs button.btn-success,.graphs button {
    background: #4318ff !important;
    color:white;
}
.graphs h1{
    color: #1B2559;
}
.graphs .form-label {
    margin-bottom: .5rem;
    color: #1B2559;
    font-size: 13px;
    font-weight: 700;
}
.pages{
    display: flex;
    flex-direction: column;
    line-height: 0px;
}

.pages p{
    font-size: 12px;
    color: #1B2559;
    font-weight: 500;
}
.pages h2{
    font-size: 20px;
    color: #1B2559;
    font-weight: 700;
}
.graphs .form-control{
    border: solid #f9f9f9 2px;
}
.graphs .form-control:focus,.form-control:active{
    border: solid #f9f9f9 2px;
    outline: 2;
    background-color: #f9f9f9;
    font-weight: 700;
    color: #1B2559;
}
.graphs .pt-5{
    padding-top: 1rem!important;
}
form.manage-fees {
    width: 50%;
    margin-bottom: 40px;
}
.graphs button.btn-success, .graphs button {
    background: #4318ff !important;
    color: white;
    max-width: 200px;
    min-width: 180px;
    padding: 3px;
    font-size: 15px;
}

.charts {
    display: flex;
    flex-direction: row;
}
.recharts-responsive-container {
    background: white;
    padding: 10px;
    margin: 20px;
    border-radius: 20px;
    width: 30.2%!important;
}
img.custom-logo {
    width: 46%;
    margin-bottom: 40px;
}

.menu-drop-de {
    height: 50px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -12px;
    border-radius: 40px;
    cursor: pointer;
}
.menu-drop-de img{
    width: 40px;
    height: 40px;
    border-radius: 100px;
    opacity: 1;
}
.menu-drop-de p{
   margin: 12px;
   display: flex;
   flex-direction: column;
   text-transform: capitalize;
   font-weight: 700;
}
.menu-drop-de p span{
    font-weight: 700;
    font-size: 12px;
    color: #323a49;
}
.footer-menu{
    position: fixed;
    bottom: 0;
    margin-bottom: 0px;
    padding: 10px;
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    left: 0;
    background: linear-gradient(90deg,hsla(0,0%,7%,1),hsla(0,0%,7%,0.9));
  
}
.footer-menu a{
    color: red!important;
    font-weight: 600;
}
.graphs button.btn-success, .graphs button {
    background: #4318ff !important;
    color: white;
    max-width: 200px;
    min-width: 112px;
    padding: 3px;
    font-size: 11px;
}
.graphs .container {
    padding: 0px;
    margin: 0px;
    margin-top: 100px !important;
    max-width: 97%;
    background: white;
    padding-top: 0pc !important;
    border-radius: 0px;
    padding-left: 44px;
    padding-bottom: 55px !important;
    height: 100%;
}
#activeClass {
    border-bottom: solid black 1px;
    border-radius: 0px !important;
    background: transparent !important;
    color: black;
    padding: 10px;
    margin-top: 0px;
}
.switchCommunity button {
    background: transparent !important;
    color: black;
}
.menu-drop a{
    text-decoration: none;
    color: black;
}
.graphs .setting-page {
    margin-left: 0% !important;
    margin-left: 15%;
    margin-top: 50px;
    float: left;
}
.non{
    font-weight: 200;
}

.breaker {
    width: 81%;
    height: 20px;
    margin-top: 40px;
    border-top: solid rgba(255,255,255,0.04) 1px;
}
.graphs .hackathonsMainDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.graphs .mg-job-container {
    padding: 0px;
    background-color: transparent;
    margin-left: -59px;
}

.graphs .mg-job-first-section h1 {
    color: white;
}
.graphs .mg-job-first-section {
    background-color: #212226;
    padding: 140px;
    padding-top: 20px;
    margin-left: 39px;
}

.supprt-and-other {
    width: 70%;
    justify-content: flex-end;
    display: flex;
    margin-top: -10px;
    padding: 10px;
    padding-right: 40px;
    border-left: solid #f9f9f9 2px;
    border-right: solid #f9f9f9 2px;
}
.supprt-and-other a{
    color: black;
    text-decoration: none;
    margin-left: 40px;
    font-size: 16px;
    font-weight: 700;
    opacity: 0.7;
}
.premium-feature a{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    color: #0068ff;
    font-weight: 700;
    font-size: 14px;
}
a.copyright {
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    bottom: 0;
    font-size: 12px;
    text-decoration: none;
    color: black;
    font-weight: 500;
}
.download-data{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 30px;
    margin-top: -90px;
}
td.manage-s .nav-item.dropdown {
    margin: 0px;
    color: black !important;
    margin-left: -30px;
}
td.manage-s a.dropdown-toggle.nav-link {
    color: white !important;
    padding: 4px;
    border-radius: 20px;
    width: 120px;
    text-align: center;
    font-size: 12px !important;
    background-color: #0068ff;
}
td.manage-s .dropdown-menu.show {
    padding: 0px !important;
    width: 0px !important;
    padding-top: 10px !important;
}
td.manage-s a.dropdown-item {
    margin-top: -20px !important;
}

.admit-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 300px;
    margin: auto;
    text-align: center;
    flex-wrap: wrap;
    font-family: 'Montserrat', sans-serif!important;
  }

  .admit-card  h2{
    width: 100%;
    text-align: center;
    font-size: 18px;
    color: #1B2559;
    margin-top: 20px;
    font-weight: 700;
  }
  
  .admit-card  {
     display: flex;
     flex-direction: row;
     margin-top: 100px;
     border-radius: 10px;
     border-left: solid #0068ff 3px;
     border-right: solid #0068ff 3px;
     overflow: hidden;
   
  }

  #shape {
    width: 102%;
}
  .admit-div{
    width: 50%;
    display: flex;
    padding: 15px;

    flex-direction: column;
  }
  .admit-card .details{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .admit-card .details label{
    font-size: 12px;
    color:#323a49;
    text-align: left;
    font-weight: 600;
    opacity: 0.6;
  }

  .admit-card .details p{
    font-size: 12px;
    color:#1B2559;
    text-align: left;
    font-weight: 600;
  }
  canvas.small {
    width: 80px !important;
    height: 80px !important;
    margin-bottom: 20px;
}
img#userprofile {
    margin-bottom: 20px;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
}
.print {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 100px;
    display: block;
}
.print button{
    background-color: #0068ff;
    color: white;
    margin: 10px;
}
  @media print {
    .admit-card-print {
      display: block; 
      display: flex;
      flex-direction: row;
    }
  
    
    body * {
      visibility: hidden;
    }
  
    .admit-card-print,
    .admit-card-print * {
      visibility: visible;
    }
    .print {
        display: none;
    }
  }
  
  
  .details.full {
    width: 100%;
    padding: 20px;
    padding-top: 0px;
}
.chat-info {
    background-color: #fff;
    padding: 10px;
    border-bottom: solid #fff 1px;
    margin-bottom: -99px !important;
    overflow: hidden;
}
.input-page {
    width: 100%;
    background: white;
    padding-bottom: 40px;
    margin-bottom: 20px;
    margin-top: -211px;
}

.msg-page-wrapper.container {
    width: 100%;
    height: 80vh;
    overflow: scroll;
}
.input-page {
    width: 85%!important;
    background: white;
    padding-bottom: 40px;
    margin-bottom: 20px;
    margin-top: -211px;
    position: fixed;
    left: 0;
    padding: 10px;
    display: flex;
    flex-direction: row;
    bottom: 0;
    margin-left: 16%;
    padding: 50px;
    margin-bottom: -20px;
    padding-top: 10px;
    margin-right:40px;
}
.input-page input{
    width: 100%;
    height: 41px;
}