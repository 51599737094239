.container {
    margin-top: 20px;
  }
  
  .table-responsive {
    overflow-x: auto;
  }
  
  .table {
    margin-bottom: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .table td, .table th {
    vertical-align: middle;
  }
  
  .table-actions {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .table-actions .btn {
    flex: 1 1 auto;
    white-space: nowrap;
  }
  

  
  .receipt-container {
    width: 80%;
    max-width: 800px;
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .receipt-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .receipt-details {
    line-height: 1.6;
  }
  
  .receipt-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .receipt-header h2 {
    margin: 0;
    font-size: 22px;
  }
  
  .receipt-header p {
    margin: 5px 0;
    color: #777;
  }
  
  .receipt-body p {
    margin: 10px 0;
  }
  
  .receipt-footer {
    text-align: center;
    margin-top: 30px;
  }
  
  .receipt-footer p {
    margin: 5px 0;
    color: #777;
  }
  

  .mark-container {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .mark-container form {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 500px;
  }
  
  .mark-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .mark-container input {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .mark-container button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .mark-container button:hover {
    background-color: #0056b3;
  }
  
  .marksheet {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
    text-align: left;
  }
  
  .mark-container .user-details {
    margin-bottom: 20px;
  }
  
  .mark-container .user-details p {
    margin: 5px 0;
  }
  
  .marks-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .marks-table th,
  .marks-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .marks-table th {
    background-color: #f2f2f2;
  }
  

  /* src/IdCard.css */
.card {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.id-card-img-top {
  width: 200px;
  margin: auto;
  height: auto;
  object-fit: cover;
}

.id-card-body {
  text-align: center;
}
